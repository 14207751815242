import React from 'react';
import FoHFoot from './Foot.js'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import volunteer1 from "./img/Volunteer1.jpg"
import volunteer2 from "./img/Volunteer2.jpg"
import volunteer3 from "./img/Volunteer3.jpg"
import Hidden from '@material-ui/core/Hidden';

export class Volunteer extends React.Component {
  render() {
    return (
      <div className="App">
        <div className="App-content">
          <Grid container spacing={8}>
            <Grid item md={8} sm={8}  xs={12}>
              <Typography variant="h3" align="center" color="inherit">Volunteer</Typography><br/>
              <Grid container spacing={8}>
                <Hidden only="xs"><Grid item md={5} sm={5} xs={12}><img src={volunteer1} alt="Volunteers" width="100%"/></Grid></Hidden>
                <Hidden only="xs"><Grid item md={2} sm={2} xs={12}><img src={volunteer2} alt="Volunteers" width="100%"/></Grid></Hidden>
                <Grid item md={5} sm={5} xs={12}><img src={volunteer3} alt="Volunteer Firefighters" width="100%"/></Grid>
              </Grid>
              <Typography variant="subtitle1" color="inherit">
                The Westerville Sunrise Rotary Club seeks volunteers to help with the Field of Heroes. In addition to the thousands of expected visitors who simply wish to walk amongst the flags, we hold numerous programs and take onsite orders throughout the weekend; all these activities are staffed completely by community volunteers.
                <br/><br/>
                As the first field of its kind in Central Ohio, the Field of Heroes has become a truly unique and inspiring event. More than two hundred volunteers are required to run the Field of Heroes each year. Volunteer opportunities range from field greeters and order-takers to overnight security personnel. We assure you that you will find it a truly rewarding experience.
                <br/><br/>
                <Grid item xs={12}>
                  <p>Volunteer sign up isn't quite ready yet. Please keep an eye on our <a href="https://www.facebook.com/fieldofheroes" target="_blank" rel="noopener noreferrer">Facebook</a> and <a href="https://twitter.com/FieldOfHeroesOH" target="_blank" rel="noopener noreferrer">Twitter</a> for an announcement when they're live!</p>
                </Grid>
                {/*
        				<Grid item xs={12}>
        					<a href="https://www.signupgenius.com/go/20f0b44aca623aaf58-field7#/" target="_blank" rel="noopener noreferrer">Field of Heroes Volunteer Opportunities</a>
        				</Grid>
        				<Grid item xs={12}>
        					<a href="https://www.signupgenius.com/go/20f0b44aca623aaf58-5krunwalk1#/" target="_blank" rel="noopener noreferrer">5K Race Volunteer Opportunities</a>
        				</Grid>
                */}
        				<br/>
                Please contact us with any questions at <a href="mailto:fohvolunteer@gmail.com">fohvolunteer@gmail.com</a>.
              </Typography>
            </Grid>
            <div className="Vertical-Line"/>
            <Grid item md={3} sm={3}  xs={12}>
              <Typography variant="h3" color="inherit">Volunteer Opportunities</Typography>
              <br/>
              <ul>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Set Up <time dateTime="2022-05-25">Friday, May 24th 9:30am - 1:00pm</time></Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Operations <time dateTime="2022-05-25">Friday, May 24th Noon - Midnight</time></Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Operations <time dateTime="2022-05-26">Saturday, May 25th 8:00am - Midnight</time></Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Operations <time dateTime="2022-05-27">Sunday, May 26th 8:00am - Midnight</time></Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Operations <time dateTime="2022-05-28">Monday, May 27th 8:00am - Noon</time></Typography>
                </li>
                <li className="FoH-Volunteer-List">
                  <Typography variant="subtitle1" color="inherit">Field Take Down <time dateTime="2022-05-28">Monday, May 27th Noon- 5pm</time></Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <div className="divider-no-margin"/>
          <FoHFoot/>
        </div>
      </div>
    );
  }
}

export default Volunteer;