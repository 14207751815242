import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter, NavLink as RRNavLink,Route} from 'react-router-dom';
import MainPage from './MainPage.js'
import Support from './Support.js'
import Volunteer from './Volunteer.js'
import Program from './Program.js'
import Schedule from './Schedule.js'
import Fivek from './Fivek.js'
import Purchase from './Purchase.js'
//import Virtual from './Virtual.js'
import videos from './Videos.js'
import pictures from './Pictures.js'
import './App.css';
import { Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink} from 'reactstrap';
import logo from './img/logo.png';
import 'bootstrap/dist/css/bootstrap.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

export class FoHNav extends React.Component {
    constructor(props) {
    super(props);

    document.body.classList.add('window');

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  componentDidMount(){
    document.title = "Field of Heroes";
  }

  render() {
    return (
      <div className="window">
        <BrowserRouter>
          <div>
            <Navbar className="FoH-Header" dark expand="md">
              <NavbarBrand tag={RRNavLink} to="/" className="sm-auto"><img src={logo} className="App-logo" alt="logo" /></NavbarBrand>
              <NavbarToggler className="Foh-Nav-Toggler mr-2" onClick={this.toggleNavbar} />
              <Collapse isOpen={!this.state.collapsed} navbar>
                <Nav navbar>
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/support"><Typography variant="h6" color="inherit">Support<Hidden smDown> the Event</Hidden></Typography></NavLink>
                  </NavItem>
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/volunteer"><Typography variant="h6" color="inherit">Volunteer<Hidden smDown> to Help</Hidden></Typography></NavLink>
                  </NavItem>
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/purchase"><Typography variant="h6" color="inherit">Purchase<Hidden smDown> a Flag</Hidden></Typography></NavLink>
                  </NavItem>
                {/*
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/virtual"><Typography variant="h6" color="inherit"><Hidden smDown>Virtual</Hidden> Dedication</Typography></NavLink>
                  </NavItem>
                */}
                {/*
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/program"><Typography variant="h6" color="inherit">Program<Hidden smDown> Info</Hidden></Typography></NavLink>
                  </NavItem>
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" target="_blank" href="https://runsignup.com/Race/OH/Westerville/2021FieldofHeroesHeroHike"><Typography variant="h6" color="inherit">Hero Hike</Typography></NavLink>
                  </NavItem>
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" target="_blank" href="http://www.foh-commemorative.org/"><Typography variant="h6" color="inherit">Commemorative Coins</Typography></NavLink>
                  </NavItem>
            */}
                  <NavItem className="FoH-Nav-Item">
                    <NavLink className="FoH-Nav-Link" tag={RRNavLink} to="/fivek"><Typography variant="h6" color="inherit">5k Run/Walk<Hidden smDown> for Heroes</Hidden></Typography></NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
            <div>
              <Route path="/" exact component={MainPage} />
            </div>
            <div>
              <Route path="/support" component={Support} />
            </div>
            <div>
              <Route path="/volunteer" component={Volunteer} />
            </div>
            <div>
              <Route path="/purchase" component={Purchase} />
            </div>
{/*
            <div>
              <Route path="/virtual" component={Virtual} />
            </div>
*/}
            <div>
              <Route path="/program" component={Program} />
            </div>
            <div>
              <Route path="/schedule" component={Schedule} />
            </div>
            <div>
              <Route path="/fivek" component={Fivek} />
            </div>
            <div>
              <Route path="/videos" component={videos} />
            </div>
            <div>
              <Route path="/photos" component={pictures} />
            </div>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

ReactDOM.render(
  <div className="window">
    <FoHNav />
  </div>,
  document.getElementById('root')
);

const App = () => (
  <div className="window">
    <FoHNav />
  </div>
);

export default App;